<template>
  <div class="band_we_chat">
    <back-step
      :currentTxt="`${$t('home.personal.bandWeChat.bdwx')}`"
      :goList="[
        {
          url: '/',
          name: `${$t('home.personal.personal.zy')}`
        },
        {
          url: '/home/personal',
          name: `${$t('home.personal.personal.grzx')}`
        }
      ]"
    />
    <div class="children_con">
      <h5 class="c_con_title">{{ $t("home.personal.bandWeChat.bdwx") }}</h5>
      <ul class="c_form">
        <li class="f_single">
          <span class="s_label required_l">
            {{ $t("home.personal.bandWeChat.wxzh") }}:
          </span>
          <div class="iup_box">
            <input v-model="bindConfig.wechatCode" />
          </div>
        </li>
        <li class="f_single" style="align-items: flex-start">
          <span class="s_label">
            {{ $t("home.personal.bandWeChat.wxewm") }}:
          </span>
          <div
            :style="
              bindConfig.wechatQrCode
                ? {
                    backgroundImage: `url(${$PicPrefix}${bindConfig.wechatQrCode})`
                  }
                : {}
            "
            class="up_load"
          >
            <el-upload
              v-show="!bindConfig.wechatQrCode"
              :action="UPLOAD_URL"
              :before-upload="beforeUpload"
              :data="{ token: uploadToken }"
              :on-error="uploadError"
              :on-success="uploadSuccess"
              :show-file-list="false"
              class="upload-demo"
              drag
              list-type="picture"
            >
              <span class="up_pic"></span>
            </el-upload>
            <span
              v-show="bindConfig.wechatQrCode"
              class="remove_pic"
              @click="() => removeFiledPic('wechatQrCode')"
            ></span>
            <p class="up_tip">上传微信二维码图片</p>
          </div>
        </li>
      </ul>
      <div class="c_foo">
        <div class="cancel_btn flex-x-y-c curp" @click="goBack">
          {{ $t("common.message.qx") }}
        </div>
        <div
          class="confirm_btn flex-x-y-c curp main_theme_color_333"
          @click="submitForm"
        >
          {{ $t("common.message.qr") }}
        </div>
      </div>
    </div>
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FullLoading from "components/full-loading/full-loading";
import BackStep from "../../../cases/cases-detail/childrenPublic/backStep";
import { UPLOAD_URL } from "common/js/config";
import { bindWechatCode } from "common/api/user";
import { getQiniuToken } from "common/api/public";
import { notifyMsg } from "common/js/util";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      UPLOAD_URL,
      bindConfig: {
        wechatQrCode: "",
        wechatCode: ""
      },
      uploadToken: ""
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  created() {
    const wxObj = sessionStorage.getItem("wxObj");
    if (wxObj) {
      this.bindConfig = {
        ...this.bindConfig,
        ...JSON.parse(wxObj)
      };
    }
    getQiniuToken().then(data => {
      this.uploadToken = data;
    });
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
    goPage(url) {
      return url && this.$router.push(url);
    },
    submitForm() {
      const { wechatCode } = this.bindConfig;
      if (!wechatCode) {
        return notifyMsg(
          this,
          "warning",
          this.$t("home.personal.bandWeChat.qtxwxh")
        );
      }
      if (!this.isLoading) {
        this.isLoading = true;
        bindWechatCode(this.bindConfig)
          .then(() => {
            this.isLoading = false;
            this.getUserMsg();
            notifyMsg(this, "warning", this.$t("common.message.czcg"));
            this.goPage("/home/personal");
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    beforeUpload(file) {
      if (/^image/.test(file.type)) {
        this.isLoading = true;
        return true;
      } else {
        notifyMsg(this, "warning", this.$t("common.message.qxzsctp"));
        return false;
      }
    },
    uploadSuccess(res) {
      this.isLoading = false;
      const { bindConfig } = this;
      this.bindConfig = {
        ...bindConfig,
        wechatQrCode: res.hash
      };
      notifyMsg(this, "success", this.$t("common.message.tpsccg"));
    },
    uploadError() {
      this.isLoading = false;
      return notifyMsg(this, "error", this.$t("common.message.scsb"));
    },
    removeFiledPic(filed) {
      const { bindConfig } = this;
      this.bindConfig = {
        ...bindConfig,
        [filed]: ""
      };
    },
    ...mapActions(["getUserMsg"])
  },
  components: {
    BackStep,
    FullLoading
  }
};
</script>

<style lang="scss" scoped>
.band_we_chat {
  width: 13.44rem;
  margin: 0 auto;
  padding-top: 40px;

  .children_con {
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    .c_con_title {
      font-size: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 0.6rem;
      color: $main_theme_color_333;
      border-bottom: 1px solid #e5e5e5;
    }

    .c_form {
      width: 5.44rem;
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;

      .f_single {
        display: flex;
        align-items: center;
        margin-bottom: 0.4rem;

        .s_label {
          width: 0.8rem;
          margin-right: 0.1rem;
          white-space: nowrap;
          display: flex;
          flex-flow: row-reverse;
          font-size: 16px;
          color: $main_theme_color_333;
        }

        .required_l:before {
          content: "*";
          color: #ff8e30;
          position: absolute;
          left: -0.1rem;
          top: 0;
          font-size: 0.16rem;
        }

        .iup_box {
          flex: 1;
          display: flex;
          align-items: center;
          position: relative;
          height: 0.52rem !important;

          input {
            flex: 1;
            border-radius: 4px;
            border: 0.01rem solid #bbbbbb;
            padding: 16px 20px;
            font-size: 14px;
            color: $main_theme_color_333;
          }

          .yzm_sp {
            margin-left: 0.1rem;
            width: 1.2rem;
            height: 0.4rem;
            border-left: 1px solid #e5e5e5;
            color: $main_theme_color;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            cursor: pointer;
          }

          .yzm_sp_active {
            color: $main_theme_color_666;
            cursor: default;
          }
        }

        .up_load {
          display: inline-block;
          border-radius: 0.03rem;
          outline: none;
          position: relative;
          width: 1.44rem;
          height: 1.44rem;
          background-size: 100% 100%;

          .remove_pic {
            position: absolute;
            right: 0.06rem;
            top: 0.06rem;
            width: 0.25rem;
            height: 0.25rem;
            cursor: pointer;
            border-radius: 100%;
            background-size: 100% 100%;
            background-image: url("/static/images/createCases/delet_img.png");
          }

          .upload-demo {
            width: 1.44rem;
            height: 1.44rem;
            background-color: #f7f7f7;
          }

          .up_tip {
            position: absolute;
            bottom: -0.25rem;
            left: 0;
            font-size: 0.14rem;
            color: $main_theme_color_333;
            white-space: nowrap;
          }
        }

        .up_pic {
          display: inline-block;
          width: 0.42rem;
          height: 0.42rem;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-size: 100% 100%;
          background-image: url("/static/images/home/web_add_codepic.png");
        }
      }
    }
  }

  .c_foo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.64rem;

    .cancel_btn {
      width: 176px;
      height: 42px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #bbbbbb;
      font-size: 16px;
      color: $main_theme_color_333;
    }

    .confirm_btn {
      width: 176px;
      height: 42px;
      background: $main_theme_color;
      border-radius: 6px;
      font-size: 16px;
      margin-left: 32px;
      color: #fff !important;
    }
  }
}

/deep/ .el-upload-dragger {
  background-color: #f0f0f0;
}
</style>
